var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.refresh}})],1)])]),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!(_vm.isLoading || _vm.isPipeLoading),"items":_vm.dataSources,"noDataMessage":"There are no data sources to display.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-icon",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[(dataSource.type === 'bigquery')?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-database"}}):_vm._e(),(dataSource.type === 'google-sheet')?_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-regular fa-file-spreadsheet"}}):_vm._e()],1)])]}},{key:"td-id",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(dataSource.id)+" ")])])]}},{key:"td-name",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('a',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center hover:tw-text-primary-300",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.routeToExplore(dataSource.id)}}},[_vm._v(" "+_vm._s(dataSource.name)+" ")])])]}},{key:"td-status",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(dataSource.status)+" ")])])]}},{key:"td-type",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(dataSource.type)+" ")])])]}},{key:"td-schemaId",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(dataSource.schemaId ? _vm.toTitleCase(dataSource.schemaId) : 'Schema id not found')+" ")])])]}},{key:"td-actions",fn:function(ref){
var dataSource = ref.item;
return [_c('td',{},[_c('div',{staticClass:"flex gap-3",staticStyle:{"text-align":"right"}},[_c('ui-button',{attrs:{"color":"secondary"},nativeOn:{"click":function($event){return _vm.routeToExplore(dataSource.id)}}},[_vm._v("Explore")]),_c('ui-dropdown',{staticClass:"tw-h-9 tw-capitalize tw-cursor-pointer",attrs:{"values":['Delete'],"align":"right"},on:{"select":function($event){return _vm.onDropdownSelect(_vm.valueSelected, dataSource)}}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-text-neutral-500",attrs:{"icon":"fa-solid fa-ellipsis-v"}})],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }