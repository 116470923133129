



















































































import axios from 'axios';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

// import { DataSvcSchema } from '../../../generated/data-svc';
import {
  DataCoreSvcDatasourcehdlCreateInput,
  DataCoreSvcDatasourcesGoogleSheetDataSource,
  // DataCoreSvcDatasourcesCreateGoogleSheetDataSource,
} from '../../../generated/data-core-svc/api';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
  apollo: {
    // wallets: {
    //   query: gql`
    //     query GetWallets($orgId: ID!, $loadFairValue: Boolean) {
    //       wallets(orgId: $orgId, loadFairValue: $loadFairValue) {
    //         id
    //         connectionId
    //         exchangeConnectionId
    //         name
    //         type
    //         address
    //         addresses
    //         networkId
    //         enabledCoins
    //         networkId
    //         groupId
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       orgId: this.$store.state.currentOrg.id,
    //     };
    //   },
    //   loadingKey: 'isLoading',
    //   errorPolicy: 'ignore',
    // },
  },
})
export default class DataSourceCreate extends BaseVue {
  public isLoading = false;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSourceType = 'Google Sheet';

  public dataSource!: { id: string; name: string } | null;

  public isValidInput = false;

  public apiBaseUrl = process.env.VUE_APP_API_URL;

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('onCreateDataSourceButtonClick')
  async onCreateDataSourceButtonClick() {
    const response = await this.createDataSource();
    return response;
  }

  public wallets: Array<Wallet> = [];

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public isCreatingDataSource = false;
  public loadedDataSources: any = [];

  public newDataSourceName = this.generateName();
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public newDataSourceType = {
    name: 'Loaded',
    id: 'loaded',
  };

  public newDataSourceGoogleSheetUri = ''; // TODO: correct scope?
  public newDataSourceGoogleSheetRange = '';
  public newDataSourceGoogleSheetSkipLeadingRows = 1;
  public newDataSourceGoogleSheetJaggedRows = false;
  public newDataSourceBigQueryTable = '';
  public newDataSourceBigQueryDataSetId = '';
  public newDataSourceBigQueryProjectId = '';
  public newDataSourceBigQueryRegion = '';

  // public schemas: DataSvcSchema[] = [];

  public accountDataSource: any = [];
  public accountsList: any[] = [];

  public get validateDataSourceName() {
    return this.newDataSourceName.length >= 3;
  }

  public get validateDataSourceGoogleSheetUri() {
    const targetPrefix = 'https://'; // TODO: check for drive url like https://docs.google.com/spreadsheets ? or pass the bq error up?
    return this.newDataSourceGoogleSheetUri.substring(0, targetPrefix.length) === targetPrefix;
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  public get dataSourceTypes() {
    const options = [
      {
        name: 'Loaded',
        id: 'loaded',
      },
      {
        name: 'Blockchain',
        id: 'blockchain',
      },
      {
        name: 'Bitwave Data',
        id: 'bitwave-data',
      },
      {
        name: 'Transform',
        id: 'transform',
      },
    ];
    return options;
  }

  public validate() {
    if (this.validateDataSourceName /* && this.newDataSourceSchema */) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public async createDataSource() {
    this.validate();
    if (this.isValidInput /* && this.newDataSourceSchema */) {
      // TODO: reenable scehma req
      this.isCreatingDataSource = true;
      try {
        let data = {};
        if (this.dataSourceType === 'Google Sheet') {
          data = {
            jaggedRows: this.newDataSourceGoogleSheetJaggedRows,
            name: this.newDataSourceName,
            sheetRange: this.newDataSourceGoogleSheetRange,
            sheetUri: this.newDataSourceGoogleSheetUri,
            skipLeadingRows: this.newDataSourceGoogleSheetSkipLeadingRows,
            type: 'google-sheet',
          };
        } else if (this.dataSourceType === 'Big Query') {
          data = {
            table: this.newDataSourceBigQueryTable,
            datasetId: this.newDataSourceBigQueryDataSetId,
            projectId: this.newDataSourceBigQueryProjectId,
            region: this.newDataSourceBigQueryRegion,
            type: 'bigquery',
            name: this.newDataSourceName,
          };
        }

        const res = await axios.post(
          this.apiBaseUrl + 'v3/orgs/' + this.$store.state.currentOrg.id + '/data-sources',
          {
            data,
          },
          {
            withCredentials: true,
          }
        );

        if (res.status === 200) {
          this.showSuccessSnackbar('Data Source Created Successfully');
          this.$emit('onCreated');
        } else {
          this.showErrorSnackbar('Error creating data source');
          console.log('Error creating data source'); // TODO: return and log error
        }

        // console.log('Data Source Created: ', res);
        return res;
      } catch (e) {
        console.log('Error creating data source: ', e);
      } finally {
        this.isCreatingDataSource = false;
      }
    }
  }
}
