

































































































import axios from 'axios';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import DataCoreApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { requestParentToNavigate } from '@/utils/iframeMessageRequester';

import { DataCoreSvcDatasourcesDataSourcePointer } from '../../../generated/data-core-svc';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    UiDataTable,
  },
})
export default class DataSourceTable extends BaseVue {
  public dataSources: DataCoreSvcDatasourcesDataSourcePointer[] = [];
  public isPipeLoading = false;

  public toTitleCase = (str: string) => str.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'id',
      label: 'Data Source Id',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'Name',
      defaultVisibility: true,
    },
    {
      id: 'type',
      label: 'Type',
      defaultVisibility: true,
    },
    // {
    //   id: 'schemaId',
    //   label: 'Schema Type',
    //   defaultVisibility: true,
    // },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   defaultVisibility: true,
    // },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  public isLoading = 0;

  async mounted() {
    await this.loadDataSources();
  }

  async routeToExplore(sourceId: string) {
    requestParentToNavigate('/dataV2/explore', { sourceId });
  }

  async loadDataSources() {
    this.isLoading = 1;
    try {
      const ds = DataCoreApiManager.getInstance();
      // TODO: handle multipage
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(this.orgId, undefined, undefined, {
        withCredentials: true,
      });
      if (resp?.data?.items && resp.status === 200) {
        this.dataSources = resp.data.items;
        console.log('got list'); // TODO: remove
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoading = 0;
    }
  }

  async deleteDataSource(dataSourceId: string): Promise<void> {
    const ds = DataCoreApiManager.getInstance();
    const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerDelete(this.orgId, dataSourceId, {
      withCredentials: true,
    });

    if (resp?.status !== 200) {
      const e = new Error('Bad response: ' + resp.status);
      this.showErrorSnackbar((e as Error).message);
    }
  }

  async onDropdownSelect(valueSelected: any, dataSource: any) {
    console.log('onDropDownSelect value ' + valueSelected + ' feed item id' + dataSource.id);
    // switch (valueSelected) {
    // case 'executeFeedPipeline':

    await this.deleteDataSource(dataSource.id);
    //   break;

    // case 'editFeed':
    //   this.handleEditFeed(feedItem);
    //   break;
    // }
  }

  // public async pipe(dataSourceId: string, sink = 'wallet') {
  //   this.isPipeLoading = true;
  //   const ds = DataCoreApiManager.getInstance();
  //   const req: DataSvcPipeRequest = {
  //     dataSourceId,
  //     sink,
  //   };
  //   try {
  //     const resp = await ds.pipe(this.orgId, req, { withCredentials: true });
  //     if (resp.status === 200) {
  //       this.showSuccessSnackbar('Succesfully piped');
  //     } else {
  //       throw new Error((resp.data as any).message);
  //     }
  //   } catch (err) {
  //     this.showErrorSnackbar(`Piping failure ${err}`);
  //   } finally {
  //     this.isPipeLoading = false;
  //   }
  // }

  public async refresh() {
    await this.loadDataSources();
  }
}
