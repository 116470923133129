






















import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import DataSourceCreate from '@/components/dataV2/DataSourceCreate.vue';
import DataSourceTable from '@/components/dataV2/DataSourceTable.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

@Component({
  components: {
    DataSourceCreate,
    DataSourceTable,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    UiDataTable,
  },
})
export default class Explore extends BaseVue {
  public isCreatingDataSource = false;

  createDataSource() {
    this.isCreatingDataSource = true;
  }

  refresh() {
    (this.$refs.dst! as any).loadDataSources();
  }
}
