




































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

import { DataSvcSchema } from '../../../generated/data-svc';
import { downloadCsv, jsonToCsv } from './dataUtils';

export enum DataSourceType {
  Loaded = 'loaded',
  Blockchain = 'blockchain',
  BitwaveData = 'bitwave-data',
  Transform = 'transform',
}

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!, $loadFairValue: Boolean) {
          wallets(orgId: $orgId, loadFairValue: $loadFairValue) {
            id
            connectionId
            exchangeConnectionId
            name
            type
            address
            addresses
            networkId
            enabledCoins
            networkId
            groupId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
})
export default class DataSourceCreate extends BaseVue {
  public isLoading = false;
  public isLoadingDataSources = false;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSourceType = 'File Import';

  public paramsArray: string[] = [];
  public paramValues: Record<string, string> = {};

  public dataSource!: { id: string; name: string } | null;

  public isValidInput = false;

  public schemaHasAccountId = false;

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('onCreateDataSourceButtonClick')
  async onCreateDataSourceButtonClick() {
    const response = await this.createDataSource();
    return response;
  }

  public wallets: Array<Wallet> = [];

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        const loaded = resp.data.items.filter((m) => m.type === 'Loaded');

        this.loadedDataSources = [];

        for (const l of loaded) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
            params: l.params,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public isCreatingDataSource = false;
  public isExistingDataSource = false;
  public loadedDataSources: any = [];

  public newDataSourceName = this.generateName();
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public newDataSourceType = {
    name: 'Loaded',
    id: 'loaded',
  };

  public schemas: DataSvcSchema[] = [];

  public accountDataSource: any = [];
  public accountsList: any[] = [];

  public get validateDataSourceName() {
    return this.newDataSourceName.length >= 3;
  }

  public downloadSchema(schema: { id: string; name: string } | undefined): void {
    try {
      if (!schema) return;
      const targetSchema = this.schemas.filter((item: DataSvcSchema) => item.id === schema.id)[0];
      const csvContent = jsonToCsv(targetSchema.definition);
      downloadCsv(csvContent, `${schema.name.replace(/\s+/g, '-').toLowerCase()}_sample_schema_file`);
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_schemaDownloadFailure'));
    }
  }

  public get schemaList() {
    return this.schemas
      .filter(
        (s) =>
          (this.allowSchemaBeta || !s.isSchemaBeta) && (s.schemaType === 'transaction' || s.schemaType === 'wallet')
      )
      .map((s) => ({ name: s.isSchemaBeta ? `${s.name} (Beta)` : s.name, id: s.id }))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  public get dataSourceTypes() {
    const options = [
      {
        name: 'Loaded',
        id: 'loaded',
      },
      {
        name: 'Blockchain',
        id: 'blockchain',
      },
      {
        name: 'Bitwave Data',
        id: 'bitwave-data',
      },
      {
        name: 'Transform',
        id: 'transform',
      },
    ];
    return options;
  }

  public validate() {
    if (this.validateDataSourceName && this.newDataSourceSchema) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public onDataChange() {
    const selectedSchema = this.schemas.find((schema) => schema.id === this.newDataSourceSchema?.id);
    const schemaParams = selectedSchema?.params?.split(',') || [];
    this.schemaHasAccountId = schemaParams.includes('accountId') ?? false;

    // remove accountId from paramsArray as it will be handled separately in the UI display: using select2 dropdown
    const unsetParams = ['accountId'];

    // return paramsArray without unsetParams -> inputs
    this.paramsArray = schemaParams.filter((param) => !unsetParams.includes(param));

    // load accounts = wallets if schema has param accountId
    if (this.schemaHasAccountId && this.wallets.length > 0) {
      this.accountsList = this.wallets.map((wallet: Wallet) => ({
        name: wallet.name,
        id: wallet.id,
      }));
    }
  }

  async mounted() {
    const loadDataCalls = [this.loadSchemas(), this.loadDataSources()];

    try {
      await Promise.all(loadDataCalls);

      if (this.dataSourceId) {
        this.dataSource = this.loadedDataSources.filter(
          (dataSource: { id: string }) => dataSource.id === this.dataSourceId
        )[0];
        this.newDataSourceName = this.dataSource?.name ?? '';
      }

      if (this.schemaId) {
        this.newDataSourceSchema = this.schemas.filter((schema) => schema.id === this.schemaId)[0];

        const loaded = this.loadedDataSources.filter(
          (dataSource: { id: string }) => dataSource.id === this.dataSourceId
        )[0];

        if (Object.keys(loaded?.params).includes('accountId')) {
          const accId = Object.assign({ accountId: '' }, loaded.params).accountId;
          this.paramValues.accountId = accId;
        }

        // call load data to check params
        this.onDataChange();
      }

      if (this.wallets) {
        this.accountsList = this.wallets.map((wallet: Wallet) => ({
          name: wallet.name,
          id: wallet.id,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loadSchemas() {
    this.isLoading = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas.filter((item) => item.status === 'Enabled');
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoading = false;
    }
  }

  public async createDataSource() {
    this.validate();
    if (this.isValidInput && this.newDataSourceSchema) {
      this.isCreatingDataSource = true;
      try {
        const ds = DataApiManager.getInstance();

        const emptyParamProperties = getEmptyStringProps(this.paramValues);

        if (this.newDataSourceSchema.id !== 'transaction' && emptyParamProperties.length !== 0) {
          this.showErrorSnackbar(
            `Parameters required for creating data source missing - ${emptyParamProperties.join(',')}`
          );
          throw new Error('Parameters required for creating data source missing');
        }

        const dsRequest = {
          name: this.newDataSourceName,
          schemaId: this.newDataSourceSchema.id,
          params: this.paramValues,
        };

        const res = await ds.createDataSources(this.orgId, dsRequest, { withCredentials: true });
        if (res.status === 200) {
          this.showSuccessSnackbar('Data Source Created Successfully');
          this.$emit('onCreated');
        } else {
          this.showErrorSnackbar('Error creating data source');
          console.log(res.data.message);
        }

        // console.log('Data Source Created: ', res);
        return res;
      } catch (e) {
        console.log('Error creating data source: ', e);
      } finally {
        this.isCreatingDataSource = false;
      }
    }
  }
}
